<template lang="pug">
  .tab-fields(v-if="fields")
    condition-field(v-for="field in beforeTabsFields" :field="field" v-model="currentValue[field.name]" :key="field.name" :allValues="currentValue")
    el-tabs(type="card" v-if="sortedTabs.length")
      el-tab-pane(v-for="(tab, index) in sortedTabs" :key="index" :label="tab.title" :disabled="isTabEmpty(index)")
        condition-field(v-for="field in tab.fields" :ref="`tab_pane_${index}`" :field="field" v-model="currentValue[field.name]" :key="field.name" :allValues="currentValue")
</template>
<script>
  import ConditionField from '@/components/fields/ConditionField'

  import isHiddenByCondition from '@/components/fields/utils/is-hidden-by-conditions'

  const wait = async(ms) => {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  export default {
    name: 'TabFields',
    props: {
      fields: { type: Array, default: () => ([]) },
      value: { type: Object, default: null }
    },
    components: {
      ConditionField
    },
    data() {
      return {
        currentValue: this.value,
        disabledTabs: []
      }
    },
    created() {
      console.log(this.currentValue)
    },
    mounted() {
      // this.checkEmptyTabs();
    },
    methods: {
      // checkEmptyTabs() {
      //   console.log('check tabs');
      //   this.disabledTabs = this.sortedTabs
      //     .map((el, index) => {
      //       return this.isTabEmpty(index) ? index : null;
      //     })
      //     .filter();
      // },
      isTabEmpty(index) {
        const fields = this.sortedTabs[index].fields
        if (!Array.isArray(fields)) {
          return false
        }
        return fields.reduce((result, field) => {
          // console.log(field, isHiddenByCondition(field.conditions, this.currentValue));
          return isHiddenByCondition(field.conditions, this.currentValue) && result
        }, true)
      }
    },
    computed: {
      tabs() {
        if (!this.fields) {
          return []
        }
        return this.fields.reduce((tabs, el) => {
          el = { ...el }
          if (el.tabbed) {
            tabs.push({
              title: el.title,
              fields: el.type === 'group' ? [el] : [],
              order: el.order
            })
          } else if (tabs.length === 0) {
            return tabs
          } else {
            const lastTab = tabs[tabs.length - 1]
            lastTab.fields.push(el)
          }
          return tabs
        }, [])
      },
      sortedTabs() {
        // sort desc by order
        let tabs = this.tabs
        tabs = [...tabs]
        return tabs.sort((a, b) => -(a.order - b.order))
      },
      beforeTabsFields() {
        return this.fields
          .reduce((obj, el) => {
            if (el.tabbed) {
              obj.hasTabs = true
            }
            if (!obj.hasTabs) {
              obj.fields.push(el)
            }
            return obj
          }, { hasTabs: false, fields: [] })
          .fields
      }
    },
    watch: {
      currentValue: {
        deep: true,
        handler() {
          // this.checkEmptyTabs();
          this.$emit('input', this.currentValue)
        }
      },
      value: {
        deep: true,
        handler() {
          // this.checkEmptyTabs();
          this.currentValue = this.value
        }
      }
    }
  }
</script>
<style lang="sass">
  .tab-fields
    margin-bottom: 10px
  .condition-field
    &__wide
      &-title
        font-size: 16px
        color: #606266
</style>
