<template lang="pug">
  .page.in-development-page
    h1 {{ title }}
    .content(v-for="(item, index) in options" :key="index" v-html="item")

    back-to-top
</template>

<script>
  import { default as request, notifyErrorRequest } from '@/utils/api'
  import BackToTop from '@/components/BackToTop'
  import TabFields from '@/components/fields/TabFields'
  import SaveRoutes from '@/views/Templates/SaveRoutes'

  export default {
    name: 'Info',
    extends: [SaveRoutes],
    components: { BackToTop },
    data() {
      return {
        loading: true,
        title: '',
        options: [],
        BackToTop
      }
    },
    computed: {
      request() {
        return this.$route.meta.request
      },
      lang() {
        return this.$store.getters.language
      },
    },
    async created() {
      const fetchedData = await this.fetchData()
      this.title = fetchedData.title
      this.options = fetchedData.options
      this.loading = false
    },
    methods: {
      async fetchData() {
        const response = await notifyErrorRequest({
          url: `${this.request}`,
          method: 'get',
          params: {},
        })
        return response
      },
    }
  }
</script>
<style rel="stylesheet/scss" lang="sass">
  @import "@/assets/sass/mixin.scss"
  .in-development-page
    margin-top: 30px
    padding: 0 30px 30px
    .content
      img
        max-width: 100%
        height: auto
</style>
